import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Grid, Card, CardContent, CardMedia, Typography, Button } from "@mui/material";
const cardStyles = {
  maxWidth: 345,
};
const mediaStyles = {
  height: 250,
  backgroundSize: "contain",
};
const ProfileList = () => {
  const [profiles, setProfiles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }
    const fetchProfiles = async () => {
      const response = await fetch('https://lit-dawn-62294.herokuapp.com/users/1', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.ok) {
        const data = await response.json();
        setProfiles(data.profiles);
        setIsLoading(false);
        if (!data.profiles.length) {
          navigate('/create-profile');
        }
      } else {
        console.log('Error fetching profiles');
      }
    };

    fetchProfiles();
  }, [navigate]);
  if (isLoading) {
    return (
      <div>
        Loading...
      </div>
    );
  }
  return (
    <div style={{ flexGrow: 1, padding: 16 }}>
      <Button component={Link} to="/create-profile">
        Create Profile
      </Button>
      <Grid container spacing={2}>
        {profiles.map((profile) => (
          <Grid item key={profile.id} xs={12} sm={6} md={4}>
            <Card style={cardStyles} component={Link} to="/dashboard">
              <CardMedia style={mediaStyles} image={profile.image} title={profile.name} />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  {profile.name}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
export default ProfileList;