import React from 'react';
import { Container, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const WelcomePage = () => {
  return (
    <Container maxWidth="xs">
      <Typography variant="h5" component="h1" align="center" gutterBottom>
        Welcome to OralOasis
      </Typography>

      <Typography align="center" gutterBottom>
        Unlock Your Smile’s Superpowers with OralOasis!
      </Typography>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button variant="contained" color="primary" component={Link} to="/login" style={{ marginRight: 10 }}>
          Login
        </Button>

        <Button variant="contained" color="primary" component={Link} to="/signup">
          Signup
        </Button>
      </div>
    </Container>
  );
};

export default WelcomePage;