import React from 'react';
import { Typography, Container } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

function DeviceUsageGraph({ title, yLabel, usageData, dataKeys }) {
return (
<Container maxWidth="sm" style={{ textAlign: 'center', marginTop: '2rem' }}>
<Typography variant="h6" gutterBottom>

{title}
</Typography>

<BarChart width={500} height={300} data={usageData}>

<CartesianGrid strokeDasharray="3 3" />

<XAxis dataKey="weekNumber" label={{ value: 'Week number', position: 'insideBottom', dy: 10 }} />
<YAxis label={{ value: yLabel, angle: -90, position: 'insideLeft', padding: { bottom: 10 } }} />
<Tooltip />

<Legend />

{dataKeys.map((key, index) => (
<Bar key={index} dataKey={key} fill={`#${Math.floor(Math.random()*16777215).toString(16)}`} />
))}
</BarChart>

</Container>

);
}

export default DeviceUsageGraph;