import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Grid } from "@mui/material";

const Dashboard = () => {
  const navigate = useNavigate();

  const handleNavClick = (navItem) => {
    navigate(`/${navItem}`);
  };

  const handleLogoutClick = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  const buttons = [
    { label: "Statistics", link: "statistics" },
    { label: "Timer", link: "timer" },
    { label: "Calendar", link: "calendar" },
    { label: "Settings", link: "settings" },
  ];

  return (
    <>
      <Grid
        container
        spacing={3}
        justifyContent="center"
        // alignItems="center"
        style={{ height: "80vh" }}
      >
        {buttons.map((button) => (
          <Grid item xs={6} key={button.link}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => handleNavClick(button.link)}
              style={{
                height: "100%",
                fontSize: "1.5rem",
                backgroundColor: "#F1813C",
              }}
            >
              {button.label}
            </Button>
          </Grid>
        ))}
      </Grid>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleLogoutClick}
        style={{ marginTop: "1rem" }}
      >
        Logout
      </Button>
    </>
  );
};

export default Dashboard;