import React, { useEffect, useRef } from 'react';

const FullScreenIframe = () => {
  const iframeRef = useRef(null);

  useEffect(() => {
    if (iframeRef.current) {
      iframeRef.current.style.minWidth = '100vw';
      iframeRef.current.style.minHeight = '100vh';
    }
  }, []);

  return (
    <iframe
      src="https://lighthearted-kleicha-31396c.netlify.app/?time=600"
      ref={iframeRef}
      title="Full-Screen iFrame"
    />
  );
}

export default FullScreenIframe;