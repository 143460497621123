import React from 'react';
import { Typography, Container } from '@mui/material';
import DeviceUsageGraph from './DeviceUsageGraph';

export function DeviceUsage() {
  // Example usage data for the past three months
  const usageData = [
    { weekNumber: 1, usagesPerWeek: 5, usageGoal: 3 },
    { weekNumber: 2, usagesPerWeek: 7, usageGoal: 3 },
    { weekNumber: 3, usagesPerWeek: 9, usageGoal: 3 },
    { weekNumber: 4, usagesPerWeek: 8, usageGoal: 4 },
    { weekNumber: 5, usagesPerWeek: 6, usageGoal: 4 },
    { weekNumber: 6, usagesPerWeek: 4, usageGoal: 4 },
    { weekNumber: 7, usagesPerWeek: 5, usageGoal: 4 },
    { weekNumber: 8, usagesPerWeek: 7, usageGoal: 4 },
    { weekNumber: 9, usagesPerWeek: 9, usageGoal: 5 },
    { weekNumber: 10, usagesPerWeek: 8, usageGoal: 5 },
    { weekNumber: 11, usagesPerWeek: 6, usageGoal: 5 },
    { weekNumber: 12, usagesPerWeek: 4, usageGoal: 5 },
    // ... add more data for the remaining weeks
  ];

  const avgData = [
    { weekNumber: 1, avgTimePerUsage: 5, avgTimeGoal: 3 },
    { weekNumber: 2, avgTimePerUsage: 7, avgTimeGoal: 3 },
    { weekNumber: 3, avgTimePerUsage: 9, avgTimeGoal: 3 },
    { weekNumber: 4, avgTimePerUsage: 8, avgTimeGoal: 4 },
    { weekNumber: 5, avgTimePerUsage: 6, avgTimeGoal: 4 },
    { weekNumber: 6, avgTimePerUsage: 4, avgTimeGoal: 4 },
    { weekNumber: 7, avgTimePerUsage: 5, avgTimeGoal: 4 },
    { weekNumber: 8, avgTimePerUsage: 7, avgTimeGoal: 4 },
    { weekNumber: 9, avgTimePerUsage: 9, avgTimeGoal: 5 },
    { weekNumber: 10, avgTimePerUsage: 8, avgTimeGoal: 5 },
    { weekNumber: 11, avgTimePerUsage: 6, avgTimeGoal: 5 },
    { weekNumber: 12, avgTimePerUsage: 4, avgTimeGoal: 5 },
  ];

  // Get the current date and time
  const currentDate = new Date();

  // Set the time to tomorrow at 9 PM
  currentDate.setDate(currentDate.getDate() + 1);
  currentDate.setHours(21);
  currentDate.setMinutes(0);
  currentDate.setSeconds(0);
  currentDate.setMilliseconds(0);

  // Format the date as a string
  const nextScheduledTime = currentDate.toLocaleString();

  return <DeviceUsageDashboard usageData={usageData} nextScheduledTime={nextScheduledTime} avgData={avgData} />;
}

function DeviceUsageDashboard({ usageData, nextScheduledTime, avgData }) {
  return (
    <Container maxWidth="sm" style={{ textAlign: 'center', marginTop: '2rem', marginBottom: '2rem' }}>
      <Typography variant="h4" gutterBottom>
        Device Usage Dashboard
      </Typography>
      <div style={{ marginTop: '2rem', marginBottom: '4rem' }}>
        <DeviceUsageGraph usageData={usageData} title={'Usages per week'} yLabel={'Number of usages'} dataKeys={['usagesPerWeek', 'usageGoal']} />
      </div>
      <div style={{ marginTop: '2rem', marginBottom: '4rem' }}>
        <DeviceUsageGraph usageData={avgData} title={'Average usage time per week'} yLabel={'Average time per usage'} dataKeys={['avgTimePerUsage', 'avgTimeGoal']} />
      </div>
      <Typography variant="body2" gutterBottom>
        Next Scheduled Usage: {nextScheduledTime}
      </Typography>
    </Container>
  );
}

export default DeviceUsageDashboard;

