import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
const SignupPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const navigate = useNavigate();
  const handleEmailChange = (event) => {
    const emailRegex = /^[^\s@]+@[^\s@]+[^\s@]+$/;
    const isValid = emailRegex.test(event.target.value);
    setIsEmailValid(isValid);
    setEmail(event.target.value);
  }
  const handlePasswordChange = (event) => {
    const isValid = event.target.value.length >= 8;
    setIsPasswordValid(isValid);
    setPassword(event.target.value);
  }
  const handleSignup = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+[^\s@]+$/;
    const isValidEmail = emailRegex.test(email);
    const isValidPassword = password.length >= 8;
    if (isValidEmail && isValidPassword) {
      const data = {
        user: {
          email: email,
          password: password,
          password_confirmation: password
        }
      };
      fetch('https://lit-dawn-62294.herokuapp.com/users', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
        .then(response => response.json())
        .then(data => {
          localStorage.setItem('token', data.token);
          navigate('/profile-list');
        })
        .catch(error => console.log(error));
    } else {
      setIsEmailValid(isValidEmail);
      setIsPasswordValid(isValidPassword);
    }
  }
  return (
    <Container maxWidth="xs">

      <Typography variant="h5" component="h1" align="center" gutterBottom>

        Signup
      </Typography>
      <form>
        <TextField
          id="email"
          label="Email"
          type="email"
          variant="outlined"
          margin="normal"
          fullWidth
          required
          value={email}
          error={!isEmailValid}
          helperText={!isEmailValid && "Please enter a valid email address"}
          onChange={handleEmailChange}
        />

        <TextField
          id="password"
          label="Password"
          type="password"
          variant="outlined"
          margin="normal"
          fullWidth
          required
          value={password}
          error={!isPasswordValid}
          helperText={!isPasswordValid && "Password must be at least 8 characters long"}
          onChange={handlePasswordChange}
        />

        <Button variant="contained" color="primary" fullWidth onClick={handleSignup} disabled={!isEmailValid || !isPasswordValid}>
          Begin your dental journey
        </Button>

        <Typography align="center" style={{ marginTop: 10 }}>
          Already have an account?{' '}
          <Link href="/login">
            Login
          </Link>
        </Typography>
      </form>
    </Container>
  );
};
export default SignupPage;