import React, { useState } from 'react';
import Calendar from 'react-calendar';
import './MonthlyCalendar.css';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
           
function MonthlyCalendar() {
  const [selectedDate, setSelectedDate] = useState(null);
  const [events, setEvents] = useState([
    { date: new Date(2023, 4, 1), usageTime: 3600 }, // Example past event
    { date: new Date(2023, 4, 10), usageTime: 7200 }, // Example past event
  ]);
  const [eventTime, setEventTime] = useState('');

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleEventSchedule = () => {
    if (selectedDate && eventTime) {
      const newEvent = { date: selectedDate, usageTime: parseInt(eventTime) };
      setEvents([...events, newEvent]);
      setEventTime('');
    }
  };

  function isSameDay(date1, date2) {
    if (!date1 || !date2) return false;
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  }

  function tileClassName({ date }) {
    const classNames = ['tile'];
    if (isSameDay(date, new Date())) {
      classNames.push('current-date');
    }
    if (isSameDay(date, selectedDate)) {
      classNames.push('selected-date');
    }
    if (events.some((event) => isSameDay(event.date, date))) {
      classNames.push('event-date');
    }
    return classNames.join(' ');
  }
  

  function tileContent({ date }) {
    const event = events.find((event) => isSameDay(event.date, date));
    const eventTime = event ? event.usageTime : null;
    return (
      <div className="tile-content">
        <div className="day">{date.getDate()}</div>
        <br />
        {eventTime && <div className="event-time">Usage Time: {eventTime} seconds</div>}
      </div>
    );
  }

  return (
    <div className="calendar-page">
      <div className="calendar-container">
        <Calendar
          onChange={handleDateChange}
          value={selectedDate}
          tileClassName={tileClassName}
          tileContent={tileContent}
        />
      </div>
      <div className="event-inputs">
        <TextField
          label="Event Time (in seconds)"
          value={eventTime}
          onChange={(e) => setEventTime(e.target.value)}
        />
        <Button variant="contained" color="primary" className="button" onClick={handleEventSchedule}>
          Schedule Event
        </Button>
      </div>
    </div>
  );
}

export default MonthlyCalendar;
