export function launchNotification(title, message, image) {
  if (!('Notification' in window)) {
    alert('This browser does not support desktop notifications');
  }

  else if (Notification.permission === 'granted') {
    new Notification(title, {
      body: message,
      icon: image
    });
  }

  else if (Notification.permission !== 'denied') {
    Notification.requestPermission(function (permission) {
      if (permission === 'granted') {
        new Notification(title, {
          body: message,
          icon: image
        });
      }
    });
  }
}