import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField, Button, Box, Typography,
  FormControl, InputLabel, Select, MenuItem
} from "@mui/material";
import { launchNotification } from './notificate';

const CreateProfile = () => {
  const [name, setName] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [imageBase64, setImageBase64] = useState("");
  const [weekdays, setWeekdays] = useState([]);
  const [duration, setDuration] = useState("");
  const [reminderHours, setReminderHours] = useState("");
  const [reminderMinutes, setReminderMinutes] = useState("");
  const navigate = useNavigate();
  const handleNameChange = (event) => {
    setName(event.target.value);
  };
  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    setImageFile(file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImageBase64(reader.result);
    };
  };
  const handleWeekdaysChange = (event) => {
    setWeekdays(event.target.value);
  };
  const handleDurationChange = (event) => {
    setDuration(event.target.value);
  };
  const handleReminderHoursChange = (event) => {
    setReminderHours(event.target.value);
  };
  const handleReminderMinutesChange = (event) => {
    setReminderMinutes(event.target.value);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("profile[name]", name);
    formData.append("profile[image]", imageBase64);
    try {
      const response = await fetch("https://lit-dawn-62294.herokuapp.com/profiles", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: formData,
      });
      const data = await response.json();

      console.log('data', data);

      const response2 = await fetch(`https://lit-dawn-62294.herokuapp.com/profiles/${data.id}/update_data`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          profile_data: {
            weekdays: weekdays,
            duration,
            reminder: `${reminderHours}:${reminderMinutes}` // added reminder time to formData
          }
        }),
      });
      const data2 = await response2.json();

      console.log('data2', data2);

      launchNotification('Reminder(s) set', `${weekdays.join(', ')}: ${reminderHours}:${reminderMinutes}.`, `url(${process.env.PUBLIC_URL}/oasis-jungle.png)`);

      navigate("/dashboard");
    } catch (error) {
      console.error(error);
    }
  };
  const PreviewImage = () => {
    if (!imageFile) return null;
    return (
      <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
        <Box
          sx={{
            height: 80,
            width: 80,
            border: "1px solid",
            borderColor: "grey.500",
            borderRadius: 1,
            overflow: "hidden",
            mr: 2,
          }}
        >
          <img
            src={imageBase64}
            alt="Profile"
            style={{ height: "100%", width: "100%", objectFit: "contain" }}
          />
        </Box>
        <Typography>{imageFile.name}</Typography>
      </Box>
    );
  };
  return (
    <Box sx={{ maxWidth: 400, mx: "auto" }}>
      <Typography variant="h5" align="center">
        Create Profile
      </Typography>

      <Box sx={{ mt: 2 }}>
        <form onSubmit={handleSubmit}>

          <TextField
            label="Name"
            value={name}
            onChange={handleNameChange}
            fullWidth
            required
          />
          <Box sx={{ mt: 2 }}>
            <Typography variant="body2">Profile Image</Typography>

            <input type="file" onChange={handleImageChange} />

            <PreviewImage />
          </Box>

          <Box sx={{ mt: 2 }}>
            <FormControl fullWidth>
              <InputLabel id="weekdays-label">Weekdays</InputLabel>
              <Select
                labelId="weekdays-label"
                id="weekdays"
                value={weekdays}
                onChange={handleWeekdaysChange}
                multiple
                required
              >
                <MenuItem value="monday">Monday</MenuItem>
                <MenuItem value="tuesday">Tuesday</MenuItem>
                <MenuItem value="wednesday">Wednesday</MenuItem>
                <MenuItem value="thursday">Thursday</MenuItem>
                <MenuItem value="friday">Friday</MenuItem>
                <MenuItem value="saturday">Saturday</MenuItem>
                <MenuItem value="sunday">Sunday</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ mt: 2 }}>
            <FormControl fullWidth>
              <InputLabel id="duration-label">How long?</InputLabel>
              <Select
                labelId="duration-label"
                id="duration"
                value={duration}
                onChange={handleDurationChange}
                required
              >
                <MenuItem value="3min">3 minutes</MenuItem>
                <MenuItem value="5min">5 minutes</MenuItem>
                <MenuItem value="10min">10 minutes</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ mt: 2 }}>
            <Typography>Reminder Time</Typography>

            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                type="number"
                label="Hours"
                value={reminderHours}
                onChange={handleReminderHoursChange}
                inputProps={{ min: 0, max: 23 }}
              />

              <Typography>:</Typography>

              <TextField
                type="number"
                label="Minutes"
                value={reminderMinutes}
                onChange={handleReminderMinutesChange}
                inputProps={{ min: 0, max: 59 }}
              />
            </Box>
          </Box>

          <Box sx={{ mt: 2 }}>
            <Button type="submit" variant="contained">
              Save
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};
export default CreateProfile;