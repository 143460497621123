import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setIsValid(event.target.value !== '' && password !== '');
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setIsValid(email !== '' && event.target.value !== '');
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isValid) {
      const response = await fetch('https://lit-dawn-62294.herokuapp.com/sessions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: email,
          password: password
        })
      });
      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('token', data.token);
        navigate('/profile-list');
      } else {
        setError('Invalid email or password. Please try again.');
      }
    }
  };
  return (
    <Container maxWidth="xs">

      <Typography variant="h5" component="h1" align="center" gutterBottom>
        Login
      </Typography>
      <Typography variant="subtitle1" align="center" gutterBottom>
        Unlock Your Smile's Superpowers with OralOasis!
      </Typography>

      <form onSubmit={handleSubmit}>
        <TextField
          id="email"
          label="Email"
          type="email"
          variant="outlined"
          margin="normal"
          fullWidth
          required
          value={email}
          onChange={handleEmailChange}
        />

        <TextField
          id="password"
          label="Password"
          type="password"
          variant="outlined"
          margin="normal"
          fullWidth
          required
          value={password}
          onChange={handlePasswordChange}
        />

        {error && (
          <Typography variant="subtitle1" color="error" align="center" gutterBottom>
            {error}
          </Typography>
        )}

        <Button variant="contained" color="primary" type="submit" fullWidth disabled={!isValid}>
          Login
        </Button>

        <Typography align="center" style={{ marginTop: 10 }}>
          Don't have an account?{' '}
          <Link href="/signup">
            Signup
          </Link>
        </Typography>
      </form>
    </Container>
  );
};
export default LoginPage;
