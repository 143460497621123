import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LoginPage from './LoginPage';
import SignupPage from './SignupPage';
import WelcomePage from './WelcomePage';
import ProfileList from './ProfileList';
import CreateProfile from './CreateProfile';
import Dashboard from './Dashboard';
import { DeviceUsage } from './DeviceUsageDashboard';
import MonthlyCalendar from './MonthlyCalendar';
import FullScreenIframe from './FullScreenIframe';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));
  useEffect(() => {
    const interval = setInterval(() => {
      setIsLoggedIn(!!localStorage.getItem('token'));
    }, 50);
    return () => clearInterval(interval);
  }, []);
  return (
    <div style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/oasis-jungle.png)`, backgroundSize: 'cover', height: '100vh' }}>
      <Router>
        <Routes>
          <Route
            exact
            path="/"
            element={
              isLoggedIn ? (
                <Navigate to="/profile-list" />
              ) : (
                <WelcomePage />
              )
            }
          />
          <Route
            exact
            path="/login"
            element={
              isLoggedIn ? (
                <Navigate to="/profile-list" />
              ) : (
                <LoginPage />
              )
            }
          />
          <Route
            exact
            path="/signup"
            element={
              isLoggedIn ? (
                <Navigate to="/profile-list" />
              ) : (
                <SignupPage />
              )
            }
          />
          <Route
            exact
            path="/dashboard"
            element={
              isLoggedIn ? (
                <Dashboard />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            exact
            path="/profile-list"
            element={
              isLoggedIn ? (
                <ProfileList />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            exact
            path="/create-profile"
            element={
              isLoggedIn ? (
                <CreateProfile />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            exact
            path="/settings"
            element={
              isLoggedIn ? (
                <CreateProfile />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            exact
            path="/statistics"
            element={
              isLoggedIn ? (
                <DeviceUsage />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            exact
            path="/timer"
            element={
              isLoggedIn ? (
                <FullScreenIframe />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route exact path="/calendar" Component={MonthlyCalendar} />
          <Route element={
            <Navigate to="/" />
          } />
        </Routes>

      </Router>

    </div>

  );
}
export default App;